$size-base: 16px;
$primary-color: #41B07B;
$secondary-color: #39D696;
$button-active-shadow: 0 0 4px 0 fade-out(#000000, 0.5) inset;
$element-shadow: 1px 1px 5px 0px fade-out(#000000, 0.5);
$toolbarColorA: #39D696;
$toolbarColorB: #218E70;
$toolbarActionColorA: #38D394;
$toolbarActionColorB: #228F6F;

/* Bootstrap */
$border-color: #dee2e6;
$primary: $primary-color;
$btn-font-weight: 500;
$modal-content-inner-border-radius: 0;
$modal-content-border-radius: 0;
$modal-content-box-shadow-xs: $element-shadow;
$modal-content-box-shadow-sm-up: $element-shadow;
$modal-content-border-color: $border-color;

@import '~bootstrap/scss/bootstrap.scss';

html, body, #root, .app-container {
    height: 100%;
    min-height: 100%;
    overflow: hidden;
}

.modal-content {
    box-shadow: $element-shadow;

    .modal-header {
        button.action {
            top: -#{(25px / $size-base)}rem;
            right: -#{(25px / $size-base)}rem;
        }
    }

    .modal-actions {
        justify-content: center;
        flex-flow: column;

        button {
            padding: 0.375rem 1.75rem;
        }
    }
}

button {
    padding: 0;

    &:focus {
        outline: none;
    }

    &:active {
        box-shadow: $button-active-shadow;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.fixImage {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
    }
}

.app-container {
    position: relative;

    .header {
        position: absolute;
        top: #{(40px / $size-base)}rem;
        right: #{(40px / $size-base)}rem;
        width: #{(250px / $size-base)}rem;
        height: #{(90px / $size-base)}rem;
        z-index: 2;

        &.logo {
            right: #{(300px / $size-base)}rem;

            &.left, &.right {
                right: #{(160px / $size-base)}rem;
            }
        }

        @extend .fixImage;

        &.left {
            .rotate {
                transform: rotate(90deg) translate(#{(80px / $size-base)}rem, -#{(80px / $size-base)}rem);
            }
        }

        &.right {
            .rotate {
                transform: rotate(270deg) translate(-#{(80px / $size-base)}rem, #{(80px / $size-base)}rem);
            }
        }
    }
}

.modes-container {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 5px;

    .modes {
        display: table;
        margin-left: 3rem;

        > .content {
            display: table-cell;
            vertical-align: middle;
        }

        .mode {
            display: inline-block;
            margin: 1rem;
            width: #{(155px / $size-base)}rem;
            height: #{(155px / $size-base)}rem;
            text-align: center;
            position: relative;
            transition: transform ease 0.1s;

            &.active {
                transform: scale(1.20, 1.20);
            }

            .border-content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                .circle {
                    width: 100%;
                    height: 100%;
                }

                .point {
                    position: absolute;
                    top: 50%;
                    right: 0;
                }
            }

            .button-content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 1rem;

                button {
                    border-radius: 1000rem;
                    border: none;
                    color: white;
                    background-color: $primary-color;
                    background-image: linear-gradient(135deg, $primary-color 0%, darken($primary-color, 20%) 100%);
                    width: 100%;
                    height: 100%;
                    box-shadow: $element-shadow;
                    padding: 0;

                    @extend .fixImage;

                    &::before {
                        border-radius: 1000rem;
                    }

                    .icon, .label {
                        width: 100%;
                        display: block;
                    }

                    .icon {
                        img {
                            max-width: #{(45px / $size-base)}rem;
                        }
                    }

                    .label {
                        font-size: 0.9em;
                        padding: 0 1.5rem;
                        line-height: 1.2;
                    }

                    &:active {
                        box-shadow: $element-shadow, $button-active-shadow;
                    }
                }
            }

            .help-box {
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;
                width: 415%;
                padding-left: #{(50px / $size-base)}rem;

                &::before {
                    content: '';
                    display: block;
                    width: #{(40px / $size-base)}rem;
                    height: #{(32px / $size-base)}rem;
                    background-color: $white;
                    border: 1px solid $border-color;
                    box-shadow: 0 0 1px 0 fade-out(#000000, 0.5);
                    position: absolute;
                    top: 50%;
                    margin-top: -#{(18px / $size-base)}rem;
                    left: #{(30px / $size-base)}rem;
                    transform: rotate(23deg) skew(-39deg, 0deg);
                    z-index: 5;
                }

                .help-container, .container, .row {
                    height: 100%;
                }

                .help-container {
                    border-radius: #{(1px / $size-base)}rem;
                    border: #{(5px / $size-base)}rem solid $white;
                    box-shadow: 0 0 1px 0 fade-out(#000000, 0.5);
                    background-color: $primary-color;
                    padding: #{(10px / $size-base)}rem;
                    position: absolute;
                    z-index: 10;

                    .container {
                        border-radius: #{(26px / $size-base)}rem;
                        background-color: $white;
                    }

                    .icon {
                        img {
                            min-width: 16px;
                            max-width: #{(80px / $size-base)}rem;
                        }

                        @extend .fixImage;
                    }

                    .btn-success {
                        border-radius: #{(13px / $size-base)}rem;
                        padding: 0.5rem 1rem;
                    }
                }

                &.left, &.right {
                    height: 200%;
                    width: calc(250% - 40px);
                    margin-top: -50%;

                    [class*="col-"] {
                        width: 100%;
                        max-width: inherit;
                        flex: auto;
                        text-align: center !important;
                    }
                }
            }
        }
    }
}

button.action {
    position: absolute;
    border: none;
    border-radius: 1000rem;
    background-color: #565455;
    width: #{(60px / $size-base)}rem;
    height: #{(60px / $size-base)}rem;
    box-shadow: $element-shadow;

    span {
        display: block;
        border-radius: 1000rem;
    }

    .content, .line, .circle {
        padding: #{(5px / $size-base)}rem;
    }

    .line {
        background-color: white;
    }

    .circle {
        background-color: #565455;

        @extend .fixImage;

        &::before {
            border-radius: 1000rem;
        }
    }

    svg.svg-inline--fa {
        width: #{(40px / $size-base)}rem;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
    }

    &.move {
        cursor: move;
    }

    &.small {
        width: #{(50px / $size-base)}rem;
        height: #{(50px / $size-base)}rem;

        .content, .line {
            padding: #{(3px / $size-base)}rem;
        }

        svg.svg-inline--fa {
            width: #{(28px / $size-base)}rem;
        }
    }

    &.red {
        transform: scale(0.9);
        background-color: $secondary-color;

        .circle {
            background-color: darken($secondary-color, 30%);
        }
    }

    &:active {
        box-shadow: $element-shadow, $button-active-shadow;
    }
}

.toolbar-box {
    height: 0;
    width: max-content;
    position: absolute;
    z-index: 100;

    .toolbar-container {
        padding: 0.5rem;
        display: inline-block;
        position: relative;
        border: #{(4px / $size-base)}rem solid #39D696;
        border-radius: 1.5rem;
        box-shadow: $element-shadow;

        .toolbar {
            padding: 1rem;
            background-color: $toolbarColorA;
            background-image: linear-gradient(135deg, $toolbarColorA 0%, $toolbarColorB 100%);
            border-radius: 1rem;
            position: relative;

            .options {
                button {
                    background-color: transparent;
                    border: none;
                    color: white;
                    padding: 0.5rem 0;
                    width: 8rem;
                    text-align: center;

                    .rotate {
                        display: block;
                    }

                    .icon {
                        display: block;
                        min-height: #{(45px / $size-base)}rem;

                        img {
                            width: #{(45px / $size-base)}rem;
                        }

                        @extend .fixImage;
                    }

                    .label {
                        display: block;
                        font-size: 0.9em;
                    }
                }
            }

            &.default, &.bottom, &.left {
                .options {
                    button {
                        border-right: #{(1px / $size-base)}rem solid white;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }

            &.top, &.right {
                .options {
                    button {
                        border-left: #{(1px / $size-base)}rem solid white;

                        &:first-child {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    button.action {
        background-color: $toolbarActionColorA;
        background-image: linear-gradient(135deg, $toolbarActionColorA 0%, $toolbarActionColorB 100%);
        right: -0.5rem;
        right: calc(-0.5rem - #{(2px / $size-base)}rem);
        margin-right: -#{(30px / $size-base)}rem;

        .circle {
            background-color: #083406;
        }

        &.rotation {
            top: -0.5rem;
            margin-top: -#{(15px / $size-base)}rem;
        }

        &.move {
            bottom: -0.5rem;
            margin-bottom: -#{(15px / $size-base)}rem;
        }
    }

    &.left {
        .toolbar {
            background-image: linear-gradient(225deg, $toolbarColorA 0%, $toolbarColorB 100%);
        }

        button.action {
            background-image: linear-gradient(225deg, $toolbarActionColorA 0%, $toolbarActionColorB 100%);
        }
    }

    &.top {
        .toolbar {
            background-image: linear-gradient(315deg, $toolbarColorA 0%, $toolbarColorB 100%);
        }

        button.action {
            background-image: linear-gradient(315deg, $toolbarActionColorA 0%, $toolbarActionColorB 100%);
        }
    }

    &.right {
        .toolbar {
            background-image: linear-gradient(45deg, $toolbarColorA 0%, $toolbarColorB 100%);
        }

        button.action {
            background-image: linear-gradient(45deg, $toolbarActionColorA 0%, $toolbarActionColorB 100%);
        }
    }
}

.spinner-box {
    height: 0;
    width: max-content;

    .spinner {
        position: relative;
        width: #{(380px / $size-base)}rem;
        height: #{(380px / $size-base)}rem;
        border-radius: 1000rem;
        z-index: 110;

        .body {
            height: 100%;
            width: 100%;
            background-size: 100%;
            background-repeat: no-repeat;
            padding: #{(60px / $size-base)}rem;

            .content {
                height: 100%;
                width: 100%;

                > .d-flex {
                    width: 100%;

                    .spinner-grow, .spinner-line {
                        width: 4rem;
                        height: 4rem;
                    }
                }

                .icon {
                    img {
                        max-width: #{(75px / $size-base)}rem;
                    }

                    @extend .fixImage;
                }

                .icon, .name, .specie, .attributes {
                    width: 100%;
                    line-height: 1.2;
                }

                .name {
                    color: #2C916F;
                    font-weight: bold;
                    font-size: 1.3em;
                    margin-top: -#{(8px / $size-base)}rem;
                    padding: 0 0.5em;
                }

                .attributes {
                    font-size: 0.85em;
                    padding: 0.5rem;
                    margin-top: 0.5rem;
                    position: relative;

                    &::before {
                        content: '';
                        background-color: #ACACAC;
                        width: 76%;
                        height: 1px;
                        position: absolute;
                        top: 0;
                        left: 12%;
                    }

                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            list-style: none;
                        }
                    }
                }
            }
        }
    }

    button.action {
        width: #{(70px / $size-base)}rem;
        height: #{(70px / $size-base)}rem;

        &.rotation {
            right: 0;
            top: #{(65px / $size-base)}rem;
        }

        &.close-spinner {
            left: #{(30px / $size-base)}rem;
            top: #{(70px / $size-base)}rem;
            width: #{(50px / $size-base)}rem;
            height: #{(50px / $size-base)}rem;
        }

        &.move {
            right: 0;
            bottom: #{(65px / $size-base)}rem;
        }
    }
}

.graphics-box {
    height: 0;

    .graphics {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        box-shadow: 0px 0px 10px 0 fade-out(#000000, 0.5);
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid $border-color;
        z-index: 120;

        .logo-box {
            background-color: white;
            border-bottom: 5px solid $primary-color;

            @extend .fixImage;

            &::before {
                z-index: 2;
            }

            img.logo {
                max-width: #{(250px / $size-base)}rem;
            }

            img.logov {
                max-height: #{(90px / $size-base)}rem;
                width: auto;
                display: block;
                margin: -#{(90px / $size-base)}rem auto 0;
                visibility: hidden;
            }

            &.left, &.right {
                .logo {
                    visibility: hidden;
                }

                .logov {
                    visibility: visible;
                }
            }
        }

        > .content {
            height: calc(100vh - #{(95px / $size-base)}rem);
            position: relative;
            overflow: auto;

            .chart-box {
                .chart-container {
                    margin: 0 auto;
                }

                .message {
                    height: 100%;
                    padding: 2em 0;

                    .alert {
                        margin: 0;
                    }

                    .spinner-grow {
                        color: $secondary-color;
                    }
                }
            }

            &.dragging {
                overflow: hidden;

                > .message {
                    height: 100%;
                }
            }

            > .message {
                position: absolute;
                height: 0;
                width: 100%;
                top: 0;
                left: 0;

                .spinner-grow {
                    color: $secondary-color;
                }
            }
        }
    }

    button.resize {
        box-shadow: -1px 0px 3px 0 fade-out(#000000, 0.5);
        background-color: rgba(255, 255, 255, 0.8);
        padding: 2rem 1rem;
        border: 1px solid $border-color;
        z-index: 119;
        cursor: ew-resize;

        .icon {
            display: block;
            width: 0px;
            height: 0px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #666;
        }
    }

    .graphic-box {
        overflow-x: auto;
        overflow-y: hidden;

        .text {
            h2 {
                @extend .h4;
                color: $primary-color;
                font-weight: bold;
                margin-bottom: 0;
                padding-left: #{(40px / $size-base)}rem;
                padding-right: #{(82px / $size-base)}rem;
                position: relative;

                &::after {
                    content: '';
                    background-color: #ACACAC;
                    width: 100%;
                    height: #{(1px / $size-base)}rem;
                    position: absolute;
                    bottom: -1rem;
                    right: -2rem;
                }

                > svg {
                    position: absolute;
                    left: 0;
                    transition: transform ease 0.3s;

                    &.active {
                        transform: rotate(90deg);
                    }
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .info {
                font-size: 1.1em;
                margin-top: 2rem;
                color: $secondary-color;
            }
        }

        .rotate {
            padding: 3rem 2rem;

            > button.action {
                background-color: transparent;
                box-shadow: none;
                position: absolute;
                top: 3rem;
                right: 2rem;
                margin-top: -$h4-font-size;

                .content {
                    border: #{(2px / $size-base)}rem solid #A8A7A7;
                }

                .line {
                    background-color: transparent;
                    padding: 0;
                }

                &:active {
                    box-shadow: $button-active-shadow;
                }
            }
        }

        .chart-config {
            .title {
                @extend .h4;
                margin-bottom: 0;
                color: #828BC4;
                //margin-left: #{(40px / $size-base)}rem;
                position: relative;
                height: #{(50px / $size-base)}rem;

                > svg {
                    position: absolute;
                    left: -#{(40px / $size-base)}rem;
                    top: #{(10px / $size-base)}rem;
                    transition: transform ease 0.3s;

                    &.active {
                        transform: rotate(90deg);
                    }
                }

                button.action {
                    color: transparent;
                    background-color: transparent;
                    background-position: center;
                    background-size: calc(110%);
                    background-repeat: no-repeat;
                    box-shadow: none;

                    .circle, .line {
                        background-color: transparent;
                    }

                    &:active {
                        box-shadow: $button-active-shadow;
                    }
                }
            }

            .form-container {
                display: inline-block;
            }

            form {
                margin: 1.5rem 0.5rem;

                .custom-checkbox {
                    margin-bottom: 0.5em;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .custom-control-label {
                        padding-left: 0.5rem;

                        &::before {
                            border-radius: 1000rem;
                            border-color: $body-color;
                            border-width: #{(2px / $size-base)}rem;
                            width: 1.2rem;
                            height: 1.2rem;
                        }

                        &::after {
                            background-image: none;
                        }
                    }

                    .custom-control-input:checked {
                        & ~ .custom-control-label {
                            &::before {
                                background-color: #2C9F6B;
                            }
                        }
                    }
                }
            }
        }

        &.rotation {
            &.left {
                .rotate {
                    transform-origin: top left;
                    transform: rotate(90deg) translateY(var(--rotate-height));
                }
            }

            &.right {
                .rotate {
                    transform-origin: top left;
                    transform: rotate(270deg) translateX(var(--rotate-width));
                }
            }
        }
    }

    .graphic-animation {
        & + {
            .graphic-animation {
                border-top: #{(1px / $size-base)}rem solid $border-color;
            }
        }
    }
}

.rotation {
    &:not(.default) {
        .rotate {
            transition: transform ease 0.3s;
        }
    }

    &.default {
        .rotate {
            transform: rotate(0deg);
        }
    }

    &.bottom {
        .rotate {
            transform: rotate(360deg);
        }
    }

    &.left {
        .rotate {
            transform: rotate(90deg);
        }
    }

    &.top {
        .rotate {
            transform: rotate(180deg);
        }
    }

    &.right {
        .rotate {
            transform: rotate(270deg);
        }
    }
}

.map-container {
    position: absolute;

    &.left {
        .legends {
            transform-origin: bottom right;
            transform: rotate(90deg) translateY(var(--legends-height));
        }
    }

    &.right {
        .legends {
            transform-origin: top left;
            transform: rotate(270deg) translateX(var(--legends-width));
        }
    }
}

.page-error {
    padding-top: 2rem;
}

.show {
    &:not(.collapse):not(.modal-backdrop) {
        animation: fadeIn 0.5s;
        opacity: 1;
    }
}

.hide {
    animation: fadeOut 0.5s;
    opacity: 0;
}

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

.web-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    overflow: auto;

    .elements-container {
        position: relative;

        .action {
            position: absolute;
            top: -#{(25px / $size-base)}rem;
            left: -#{(25px / $size-base)}rem;
        }
    }
}

.rotation {
    &.left, &.right {
        .screenshot-container {
            max-width: 95vh;
        }
    }
}

.screenshot-container {
    .elements-container {
        padding: #{(5px / $size-base)}rem;

        .img-container {
            > img {
                border: #{(1px / $size-base)}rem solid $border-color;
            }
        }

        button.action {
            top: -#{(25px / $size-base)}rem;
            left: -#{(25px / $size-base)}rem;
            z-index: 1;
        }
    }

    .elements-actions {
        border-top: none;

        > * {
            width: 100%;
            text-align: center;
        }

        button {
            svg {
                margin-right: 0.7rem;
            }
        }

        .input-group-append {
            button {
                .text {
                    margin-left: 0.5em;
                }
            }
        }
    }
}

.screenshot-keyboard-container {
    max-width: #{(600px / $size-base)}rem;

    button.action {
        top: -#{(25px / $size-base)}rem;
        left: -#{(25px / $size-base)}rem;
        z-index: 1;
    }

    .keyboard {
        margin-top: #{(10px / $size-base)}rem;
    }

    .input-group-append {
        button {
            svg {
                margin-right: 0.7rem;
            }

            .text {
                margin-left: 0.5em;
            }
        }
    }
}

.web-box {
    background-color: rgba(255, 255, 255, 0.95);

    .elements-container {
        width: 100%;
        height: 100%;
        padding: #{(40px / $size-base)}rem;

        .back {
            border: none;
            background-color: transparent;
            max-width: #{(120px / $size-base)}rem;

            .icon, .text {
                display: block;
            }

            .icon {
                padding: #{(10px / $size-base)}rem #{(17px / $size-base)}rem;
                border: #{(3px / $size-base)}rem solid $primary-color;
                border-radius: 1000rem;
                background-color: $white;
                box-shadow: $element-shadow;
            }

            .text {
                color: darken($primary-color, 10%);
                font-size: 1.2em;
                font-weight: bold;
                margin-top: 0.8em;
            }

            &:active {
                box-shadow: none;

                .icon {
                    box-shadow: $element-shadow, $button-active-shadow;
                }
            }
        }

        .container-fluid, .row, .column, .navigator, .content {
            height: 100%;
        }

        .navigator {
            border: 1px solid $border-color;
            box-shadow: $element-shadow;

            .tabs {
                overflow: hidden;
                background-color: #3B455A;

                .tab {
                    text-overflow: ellipsis;
                    background-color: #E8E8E8;
                    display: inline-block;
                    padding: 1em 3em;
                }
            }

            .navigation {
                background-color: #00C7C7;
                padding: 1em 0 0.5em;

                .form-row {
                    > .text-right {
                        min-width: max-content;
                    }
                }

                .form-group {
                    margin-bottom: 0;
                    width: 100%;

                    input {
                        border-radius: 0;
                    }
                }

                .btn-group {
                    .btn {
                        padding-top: 0;
                        padding-bottom: 0;
                        color: fade-out($white, 0.4);

                        &:active, &:hover, &:focus {
                            color: fade-out($white, 0.2);
                        }
                    }
                }
            }

            .content {
                background-color: $white;

                iframe {
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }
}

.filters-box {
    max-width: #{(750px / $size-base)}rem;

    .filters-title {
        color: darken($primary-color, 10%);
    }

    .range.disabled {
        opacity: 0.7;
        pointer-events: none;
    }

    .filters-container {
        padding: 2.5rem;

        .input-range {
            margin: 2em 0;

            .input-range__label--min, .input-range__label--max {
                display: none;
            }
        }
    }

    .modal-actions {
        button {
            min-width: #{(200px / $size-base)}rem;
        }
    }
}

.input-range {
    height: 2rem;
    margin: 1.5em 0;

    .input-range__track--active {
        background: $primary-color;
    }

    .input-range__track {
        height: 0.4rem;
    }

    .input-range__slider {
        background: $primary-color;
        border-color: $white;
        border-width: 2px;
        box-shadow: 0 1px 0 0 fade-out(#000000, 0.5);
        width: 1.5rem;
        height: 1.5rem;
        margin-top: -0.90rem;
        margin-left: -0.75rem;
    }

    .input-range__label {
        top: -2.2rem;
        font-size: 0.9rem;

        .input-range__label-container {
            color: $body-color;
        }
    }

    .input-range__label--min, .input-range__label--max {
        bottom: -1rem;
    }
}

.ol-control {
    border-radius: #{(4px / $size-base)}rem;

    &.legends {
        bottom: #{(20px / $size-base)}rem;
        left: #{(20px / $size-base)}rem;
        padding: 0;

        .bar-legends {
            background-color: $white;
            border: #{(1px / $size-base)}rem solid #2ca06c;
            border-radius: #{(5px / $size-base)}rem;
            text-align: left;
            font-size: #{(12px / $size-base)}rem;

            .bar-header {
                padding: #{(5px / $size-base)}rem;
                background-color: #2ca06c;
                width: 100%;
                color: $white;
            }

            .bar-content {
                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        padding: #{(5px / $size-base)}rem;
                        height: #{(25px / $size-base)}rem;
                        width: 100%;

                        .icon {
                            width: #{(12px / $size-base)}rem;
                            height: #{(12px / $size-base)}rem;
                            display: inline-block;
                            margin-right: #{(5px / $size-base)}rem;
                            border-radius: #{(10px / $size-base)}rem;
                            border: 2px solid transparent;
                        }
                    }
                }
            }
        }
    }

    &.layer {
        top: #{(20px / $size-base)}rem;
        left: #{(20px / $size-base)}rem;
        background-color: fade-out(#FFF, 0.2);
        border: 1px solid $border-color;
        box-shadow: 0px 1px 4px fade-out(#000000, 0.7);

        &:hover {
            background-color: fade-out(#FFF, 0.1);
        }

        button {
            position: relative;
            width: #{(75px / $size-base)}rem;
            height: #{(75px / $size-base)}rem;
            font-size: #{(14px / $size-base)}rem;
            padding: 0;
            border-radius: #{(4px / $size-base)}rem;

            .content {
                display: block;
                height: 100%;
                width: 100%;

                .text {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: #{(20px / $size-base)}rem #{(10px / $size-base)}rem #{(15px / $size-base)}rem;
                    width: 100%;
                    text-align: left;
                    font-weight: normal;
                    background-image: linear-gradient(transparent, fade-out(#000000, 0.4));
                }
            }
        }
    }

    &.help {
        top: #{(20px / $size-base)}rem;
        left: #{(120px / $size-base)}rem;
        padding: 0;
        border-radius: 1000rem;

        button {
            width: #{(32px / $size-base)}rem;
            height: #{(32px / $size-base)}rem;
            background-color: transparent;
            padding: 0;
            margin: 0;
            border-radius: 1000rem;
            box-shadow: 0px 1px 4px fade-out(#000000, 0.7);

            img {
                height: 100%;
            }

            &:hover, &:focus {
                background-color: transparent;
            }

            &:active {
                box-shadow: 0px 1px 4px fade-out(#000000, 0.7), $button-active-shadow;
            }
        }
    }
}

.search-box {
    position: absolute;
    height: #{(150px / $size-base)}rem;
    width: #{(600px / $size-base)}rem;
    margin-left: #{(15px / $size-base)}rem;
    transition: top ease 0.1s, left ease 0.1s;
    z-index: 101;

    &.right-arrow {
        margin-left: -#{(600px / $size-base)}rem;

        &::before {
            left: 100%;
            margin-left: -#{(65px / $size-base)}rem;
            margin-top: -#{(62px / $size-base)}rem;
        }

        .search-container {
            margin-right: #{(45px / $size-base)}rem;
            margin-left: -#{(45px / $size-base)}rem;
        }
    }

    &.fadeOut {
        visibility: hidden;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: #{(30px / $size-base)}rem;
        content: '';
        display: block;
        width: #{(40px / $size-base)}rem;
        height: #{(32px / $size-base)}rem;
        background-color: $white;
        border: 1px solid $border-color;
        box-shadow: 0 0 1px 0 fade-out(#000000, 0.5);
        margin-top: -#{(12px / $size-base)}rem;
        transform: rotate(23deg) skew(-39deg, 0deg);
        z-index: 5;
    }

    .container, .row {
        height: #{(125px / $size-base)}rem;
    }

    .search-container {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: #{(157px / $size-base)}rem;
        transition: margin ease 0.25s;
        margin-left: #{(50px / $size-base)}rem;
        padding: #{(5px / $size-base)}rem;
        border-radius: #{(1px / $size-base)}rem;
        border: #{(1px / $size-base)}rem solid fade-out(#000000, 0.8);
        background-color: $white;
        margin-top: var(--search-margin-center);

        .search-bg {
            padding: #{(10px / $size-base)}rem;
            background-color: $primary-color;
        }

        .container {
            position: relative;
            border-radius: #{(26px / $size-base)}rem;
            background-color: $white;
            width: 100%;

            button.action {
                top: #{(-34px / $size-base)}rem;
                right: #{(-36px / $size-base)}rem;
                background-color: $toolbarActionColorA;
                background-image: linear-gradient(135deg, $toolbarActionColorA 0%, $toolbarActionColorB 100%);

                .circle {
                    background-color: #083406;
                }
            }
        }

        .icon {
            img {
                min-width: 16px;
                max-width: #{(80px / $size-base)}rem;
            }

            @extend .fixImage;
        }

        .text {
            @extend .form-control;
            border-color: transparent;
        }

        input {
            border-color: transparent;
        }

        .suggestions {
            background-color: $white;
            padding: 0 #{(5px / $size-base)}rem;
            border: #{(1px / $size-base)}rem solid fade-out(#000000, 0.8);
            border-top: none;
            margin-left: #{(-6px / $size-base)}rem;
            margin-right: #{(-6px / $size-base)}rem;

            &.virtual {
                border-bottom: none;
            }

            .horizontal-menu {
                .menu-wrapper {
                    padding: #{(5px / $size-base)}rem 0;

                    .menu-wrapper--inner {
                        padding: #{(1px / $size-base)}rem;
                    }
                }
            }

            .menu-item-wrapper {
                outline: none;

                .btn {
                    margin-right: #{(5px / $size-base)}rem;
                }
            }
        }
    }

    .results {
        height: #{(150px / $size-base)}rem;
        background: $white;
        margin-top: #{(-50px / $size-base)}rem;
        border: 1px solid $border-color;

        .list {
            width: 100%;
            height: #{(147px / $size-base)}rem;

            .list-item {
                border-color: transparent;
                box-shadow: none;

                .highlight {
                    background-color: transparent;
                    padding: 0;
                    color: $primary-color;
                }
            }
        }
    }

    &.searching {
        .search-container {
            height: #{(255px / $size-base)}rem;
        }

        .text, input {
            margin-top: -#{(40px / $size-base)}rem;
        }

        .keyboard {
            margin-top: #{(95px / $size-base)}rem;
        }

        .status {
            width: 100%;

            &.fadeOut {
                display: none !important;
            }
        }
    }

    &.active-keyboard {
        .search-container {
            border-bottom: none;
            padding-bottom: 0;
        }

        &.searching {
            .search-container {
                height: #{(247px / $size-base)}rem;
            }
        }
    }

    .keyboard {
        margin-left: -#{(6px / $size-base)}rem;
        width: calc(100% + #{(12px / $size-base)}rem);
        background-color: $white;
        border: #{(1px / $size-base)}rem solid fade-out(#000000, 0.8);
        border-top: none;
        padding: 0 #{(5px / $size-base)}rem #{(5px / $size-base)}rem;

        .react-simple-keyboard {
            background-color: #E4E4E4;
            border-radius: 0;
        }
    }

    &.rotation {
        &.left, &.right {
            width: #{(500px / $size-base)}rem;

            .search-container {
                margin-top: 0;
            }
        }

        &.top {
            .search-container {
                margin-top: calc(-1 * var(--search-margin-center));
            }
        }

        &.left {
            .search-container {
                transform: rotate(90deg) translateY(calc(var(--keyboard-translate-y) - #{(50px / $size-base)}rem));
            }
        }

        &.right {
            .search-container {
                transform: rotate(270deg) translateY(calc(-1 * (var(--keyboard-translate-y) - #{(50px / $size-base)}rem)));
            }
        }

        &.active-keyboard {
            &:not(.right-arrow) {
                &.left {
                    .search-container {
                        transform: rotate(90deg) translateY(calc(var(--keyboard-translate-y) - var(--keyboard-height) - #{(44px / $size-base)}rem));
                    }
                }

                &.searching {
                    &.left, &.right {
                        .search-container {
                            margin-top: -#{(44px / $size-base)}rem;
                        }
                    }

                    &.top {
                        .search-container {
                            margin-top: calc(-1 * (var(--search-margin-center) + #{(87px / $size-base)}rem));
                        }
                    }

                    &.left {
                        .search-container {
                            transform: rotate(90deg) translateY(calc(var(--keyboard-translate-y) - var(--keyboard-height) - #{(94px / $size-base)}rem));
                        }
                    }

                    &.right {
                        .search-container {
                            transform: rotate(270deg) translateY(calc(-1 * (var(--keyboard-translate-y) - #{(94px / $size-base)}rem)));
                        }
                    }
                }
            }

            &.right-arrow {
                &.left, &.right {
                    margin-left: -#{(500px / $size-base)}rem;

                    &::before {
                        margin-top: -#{(12px / $size-base)}rem;
                    }
                }

                &.top {
                    .search-container {
                        margin-top: calc(-1 * var(--search-margin-center) - #{(94px / $size-base)}rem);
                    }
                }

                &.left {
                    .search-container {
                        transform: rotate(90deg) translateY(calc((-1 * var(--keyboard-translate-y)) + #{(50px / $size-base)}rem));
                    }
                }

                &.right {
                    .search-container {
                        transform: rotate(270deg) translateY(calc(var(--keyboard-translate-y) - var(--keyboard-height) - #{(44px / $size-base)}rem));
                    }
                }

                &.searching {
                    &.left, &.right {
                        .search-container {
                            margin-top: -#{(44px / $size-base)}em !important;
                        }
                    }

                    &.top {
                        .search-container {
                            margin-top: calc(-1 * var(--search-margin-center) - #{(192px / $size-base)}rem);
                        }
                    }

                    &.left {
                        .search-container {
                            transform: rotate(90deg) translateY(calc(var(--keyboard-translate-y) - var(--keyboard-height) - 112px));
                        }
                    }

                    &.right {
                        .search-container {
                            transform: rotate(270deg) translateY(calc(-1 * (var(--keyboard-translate-y) - 112px)));
                        }
                    }
                }
            }
        }
    }
}

.items-box {
    --items-width: 200px;

    position: absolute;
    top: #{(90px / $size-base)}rem;
    min-width: #{(50px / $size-base)}rem;
    margin-right: #{(75px / $size-base)}rem;
    overflow: hidden;

    &.only-item {
        top: #{(180px / $size-base)}rem;
    }

    .title {
        position: fixed;
        top: #{(90px / $size-base)}rem;
        padding: 0.5em 1.5em;
        font-size: 0.9em;
        margin-left: #{(13px / $size-base)}rem;
        width: var(--items-width);
    }

    .box {
        max-height: calc(100vh - #{(180px / $size-base)}rem);

        .box-content {
            padding: #{(13px / $size-base)}rem;
            padding-top: #{(60px / $size-base)}rem;
            overflow: visible;
        }
    }

    .item-container {
        height: #{(100px / $size-base)}rem;
        display: flex;
        align-items: center;
        width: var(--items-width);
    }

    .item {
        background-color: #335F7F;
        border: none;
        color: $white;
        text-align: center;
        border-radius: $border-radius 2rem 2rem $border-radius;
        padding: 0.5em calc(0.5em + #{(72px / $size-base)}rem) 0.5em 0.7em;
        position: relative;
        height: #{(54px / $size-base)}rem;
        width: calc(100%);
        font-size: 0.9em;

        &.add {
            .name {
                margin-top: -#{(3px / $size-base)}rem;
            }

            .info {
                font-size: 1em;
            }
        }

        .info {
            font-size: 0.85em;
        }

        .name, .info {
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: none;
            white-space: nowrap;
        }

        .icon {
            position: absolute;
            top: -#{(9px / $size-base)}rem;
            right: 0;
            height: #{(72px / $size-base)}rem;
            width: #{(72px / $size-base)}rem;
            background-color: #45749D;
            border-radius: 100rem;
            box-shadow: $element-shadow;
            padding: #{(15px / $size-base)}rem;

            @extend .fixImage;
        }

        .action {
            transform: scale(0.5);
            left: -#{(25px / $size-base)}rem;
            top: -#{(25px / $size-base)}rem;
            background-color: #828282;

            .circle {
                background-color: #C4C4C3;
            }
        }
    }

    button.item {
        &:active {
            .icon {
                box-shadow: $element-shadow, $button-active-shadow;
            }
        }
    }

    &.rotation {
        &.left, &.right {
            --items-width: 210px;

            .title {
                width: #{(200px / $size-base)}rem;
                margin-left: 0;
            }

            .box {
                .box-content {
                    padding-top: #{(13px / $size-base)}rem;
                }
            }

            .item-container {
                height: var(--items-width);
                margin-left: -#{(50px / $size-base)}rem;
                margin-right: -#{(50px / $size-base)}rem;

                .item {
                    width: calc(100% - #{(46px / $size-base)}rem);
                }
            }
        }

        &.left {
            .box {
                .box-content {
                    padding-bottom: 0;
                }
            }

            .title {
                transform: rotate(90deg) translate(#{(85px / $size-base)}rem, -#{(48px / $size-base)}rem);
            }
        }

        &.right {
            .box {
                .box-content {
                    padding-top: 0;
                }
            }

            .title {
                transform: rotate(270deg) translate(-#{(85px / $size-base)}rem, #{(48px / $size-base)}rem);
            }

            .item-container {
                .item {
                    margin-left: #{(18px / $size-base)}rem;
                }
            }
        }
    }
}

.disable-doubletap-to-zoom, .modal-content, .modal.show {
    touch-action: none;
}

.map-box {
    .fadeOut  {
        height: 0;
        overflow: hidden;
    }

    &.loading {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 99999;
        font-weight: 500;

        .fadeIn {
            height: 100%;
            background-color: fade-out($white, 0.3);

            .spinner-grow {
                color: $secondary-color;
            }
        }
    }
}

.scrollarea .scrollbar-container {
    background-color: #AFB2B2;

    .scrollbar {
        background-color: #595B5B;
    }
}

.simple-keyboard.hg-theme-default .hg-button {
    height: #{(40px / $size-base)}rem;
}

.screenshot {
    .animated.fadeOut {
        display: none !important;
    }

    * {
        transition: none !important;

        &:not(.help-container) {
            box-shadow: none !important;
        }
    }

    .scrollarea .scrollbar-container {
        background-color: fade-out(#AFB2B2, 0.5);

        .scrollbar {
            background-color: fade-out(#595B5B, 0.5);
        }
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: fade-out(#000, 0.4);
    z-index: 9999;
    color: $white;
    font-size: 1.2em;

    &.left, &.right {
        .modes {
            .mode {
                .text {
                    width: #{(155px / $size-base)}rem;
                    text-align: center !important;
                }
            }
        }
    }

    &.default {
        .title {
            transform: rotate(0) translate(calc(50vw - (var(--overlay-title-width) / 2)), #{(50px / $size-base)}rem);
        }
    }

    &.bottom {
        .title {
            transform: rotate(360deg) translate(calc(50vw - (var(--overlay-title-width) / 2)), #{(50px / $size-base)}rem);
        }
    }

    &.left {
        .title {
            transform-origin: top left;
            transform: rotate(90deg) translate(calc((50vh - var(--overlay-title-width) / 2)), calc((-1 * (var(--overlay-title-height) / 2)) - 50vw));
        }
    }

    &.top {
        .title {
            transform: rotate(180deg) translate(calc(-1 * (50vw - (var(--overlay-title-width) / 2))), -#{(50px / $size-base)}rem);
        }
    }

    &.right {
        .title {
            transform-origin: top left;
            transform: rotate(270deg) translate(calc(-1 * (50vh + (var(--overlay-title-width) / 2))), calc(50vw - (var(--overlay-title-height) / 2)));
        }
    }

    button.action {
        position: absolute;
        right: 3rem;
        top: 3rem;
        background-color: $white;
        z-index: 1;

        .icon {
            display: inline-block;
            @extend .fixImage;
        }
    }

    .container-overlay {
        height: 100vh;

        .title  {
            display: inline-block;
            text-transform: uppercase;
            margin: 0;

            .logo {
                display: inline-block;
                @extend .fixImage;

                img {
                    max-width: 100px;
                    height: auto;
                    margin-right: #{(20px / $size-base)}rem;
                }
            }
        }

        .modes {
            margin-left: 0;
            padding-left: 3rem;

            .content {
                .mode {
                    width: calc(50vw - 5rem);

                    img {
                        max-width: #{(100px / $size-base)}rem;
                    }

                    .icon {
                        margin-right: #{(20px / $size-base)}rem;
                        @extend .fixImage;
                    }

                    .content {
                        height: 100%;
                        padding-left: #{(200px / $size-base)}rem;
                    }
                }
            }
        }

        .toolbar {
            position: absolute;
            left: 50vw;
            bottom: 0;
            width: 50vw;
            max-width: #{(800px / $size-base)}rem;

            ul {
                padding: #{(100px / $size-base)}rem;
                margin: 0 -#{(50px / $size-base)}rem -#{(50px / $size-base)}rem 0;

                li {
                    padding: #{(10px / $size-base)}rem 0;
                    list-style: none;
                    @extend .fixImage;

                    img {
                        width: #{(65px / $size-base)}rem;
                        height: auto;
                        margin-right: #{(20px / $size-base)}rem;
                    }

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}